import React from 'react';
import { FoodItem, Option, ResolvedResponse } from '../types';
import { useFetch } from './useFetch';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';

export const useOptions = (
  toggle: () => void,
  editOption?: Option,
  callback?: (o: Option) => void
) => {
  const queryClient = useQueryClient();

  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState<Option>({ name: '', price: 0 });

  const { useFetchFoodItem } = useFetch();
  const { data: foodItems } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { count: 500 }
  );

  const onGetInputValue = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setOption({
      ...option,
      [name]: name === 'price' ? parseInt(value) : value,
    });
  };

  const createOption = async (e?: React.SyntheticEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setLoading(true);

    if (!option.name) return setLoading(false);

    if (!loading) {
      try {
        if (editOption) {
          await mealsApi.updateOption(editOption.id!, option);

          toast.success('Option updated!');
        } else {
          const createdOption = await mealsApi.createOption(option);

          if (createdOption && createdOption.data && callback) {
            callback(createdOption.data.data);
          }

          toast.success('Option created!');
        }

        queryClient.refetchQueries(['options']);

        toggle();
      } catch (error) {
        toast.error(parseError(error));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleMultiSelect: IOnChange<FoodItem> = (items) => {
    setOption({
      ...option,
      foodItem: (items as FoodItem).id,
    });
  };

  React.useEffect(() => {
    if (editOption) {
      setOption({
        name: editOption.name ?? '',
        price: editOption.price,
        foodItem: editOption.sameAsFoodItem?.id,
      });
    }
  }, [editOption]);

  return {
    option,
    loading,
    foodItems,
    createOption,
    onGetInputValue,
    handleMultiSelect,
  };
};
