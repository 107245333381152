import React, { SyntheticEvent } from 'react';
import { filters, tableData } from '../../../../constants';
import { useAppDispatch, useAppSelector, useFetch } from '../../../../hooks';
import {
  FoodGroup,
  FoodItem,
  Option,
  ResolvedResponse,
} from '../../../../types';
import {
  Drawer,
  Loading,
  Paginator,
  PrimaryButton,
  PrimarySelect,
  Table,
} from '../../../components';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { brandsSelector, getAllBrands } from '../../../../store/slices';
import CreateFoodItem from './create';
import { FoodItemScreenFilters } from './types';
import Modal from 'react-modal';
import { AddFoodGroup, AddOption } from './create/add-food-group';

const FoodItemsScreen = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { brands } = useAppSelector(brandsSelector);

  const [page, setPage] = React.useState(1);
  const [modal, setModal] = React.useState(0);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [activeBrand, setActiveBrand] = React.useState<number>();
  const [editGroup, setEditGroup] = React.useState<string>();
  const [editOption, setEditOption] = React.useState<Option>();
  const [activeFilter, setActiveFilter] =
    React.useState<FoodItemScreenFilters>('FOOD_ITEMS');

  const { useFetchFoodItem, useFetchFoodGroup, useFetchOptions } = useFetch();

  const { data, isLoading } = useFetchFoodItem<ResolvedResponse<FoodItem[]>>(
    undefined,
    { page, count: 45, brand: activeBrand },
    { enabled: activeFilter === 'FOOD_ITEMS' }
  );

  const { data: foodGroups, isLoading: groupsLoading } = useFetchFoodGroup<
    ResolvedResponse<FoodGroup[]>
  >(
    undefined,
    { page, count: 45 },
    { enabled: activeFilter === 'FOOD_GROUPS' }
  );

  const { data: options, isLoading: optionsLoading } = useFetchOptions(
    { page, count: 45 },
    { enabled: activeFilter === 'OPTIONS' }
  );

  const filterList = (filter: FoodItemScreenFilters) => {
    searchParams.set('list', filter);
    searchParams.set('page', '1');
    navigate(`/dashboard/food-items?${searchParams.toString()}`);
  };

  const editItem = (
    id?: string,
    e?: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();

    if (activeFilter === 'FOOD_GROUPS') {
      setEditGroup(id);
      return setModal(1);
    }

    if (activeFilter === 'OPTIONS') {
      const g = options?.data?.find((group) => group.id === id);

      if (g?.id) {
        setEditOption(g);
        return setModal(3);
      }
    }

    if (id) {
      return navigate(`/dashboard/food-items/edit/${id}`);
    }
  };

  const currentPage = React.useMemo(
    () => parseInt(searchParams.get('page') ?? '1'),
    [searchParams]
  );

  const filterFoodItems = (event: SyntheticEvent<HTMLSelectElement>) => {
    const value = event.currentTarget.value;

    setActiveBrand(parseInt(value));
  };

  const handlePageClick = (data: any) => {
    const page = data.selected + 1;
    setPage(page);

    searchParams.set('page', page);
    navigate(`/dashboard/food-items?${searchParams.toString()}`);
  };

  const duplicateItem = (id: string) => {
    if (id && activeFilter === 'FOOD_ITEMS') {
      return navigate(`/dashboard/food-items/create/?duplicate=${id}`);
    }

    if (id && activeFilter === 'FOOD_GROUPS') {
      setEditGroup(id);
      return setModal(2);
    }

    if (id && activeFilter === 'OPTIONS') {
      const g = options?.data?.find((group) => group.id === id);

      if (g?.id) {
        setEditOption(g);
        return setModal(4);
      }
    }
  };

  const createItem = () => {
    if (activeFilter === 'FOOD_ITEMS') {
      return navigate(`/dashboard/food-items/create/`);
    }
    if (activeFilter === 'FOOD_GROUPS') {
      return setModal(1);
    }
    if (activeFilter === 'OPTIONS') {
      return setModal(3);
    }
  };

  const closeModal = () => {
    setEditGroup(undefined);
    setEditOption(undefined);
    setModal(0);
  };

  React.useEffect(() => {
    brands && brands.length < 1 && dispatch(getAllBrands());
    // eslint-disable-next-line
  }, [dispatch]);

  React.useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('create')) {
      setOpenDrawer(true);
    } else {
      setOpenDrawer(false);
    }
  }, [pathname]);

  React.useEffect(() => {
    const list = searchParams.get('list');
    const page = searchParams.get('page');

    list
      ? setActiveFilter(list as FoodItemScreenFilters)
      : setActiveFilter('FOOD_ITEMS');
    page && setPage(parseInt(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <div className="body1">
        {((isLoading && activeFilter === 'FOOD_ITEMS') ||
          (groupsLoading && activeFilter === 'FOOD_GROUPS') ||
          (optionsLoading && activeFilter === 'OPTIONS')) && <Loading />}
        <>
          <div className="header1">
            <h1 className="semibold text-xl">Food Item Management</h1>
            <div className="flex space-x-4">
              <PrimaryButton
                text={`Create ${
                  tableData(data, foodGroups, options)[activeFilter].name
                }`}
                onClick={createItem}
              />
            </div>
          </div>
        </>

        <div className="grid grid-cols-3 gap-4 mt-4">
          {filters(
            data?.pagination?.totalRecords,
            foodGroups?.pagination?.totalRecords,
            options?.pagination?.totalRecords
          ).map(({ name, value, count }) => (
            <button
              onClick={() => filterList(value)}
              className={`p-4 border rounded flex justify-between ${
                activeFilter === value
                  ? 'border-primary-brown bg-primary-brown text-white'
                  : 'text-primary-600'
              }`}
              key={value}
            >
              <p className="bold">{name}</p>
              <p className="bold">{count}</p>
            </button>
          ))}
        </div>

        {activeFilter === 'FOOD_ITEMS' && (
          <PrimarySelect
            onChange={filterFoodItems}
            placeholder="All Brands"
            options={brands}
            optionLabel="name"
            optionValue="id"
          />
        )}

        <Table
          data={tableData(data, foodGroups, options)[activeFilter].data}
          columns={
            tableData(data, foodGroups, options, brands)[activeFilter].column
          }
          tableFor={tableData(data, foodGroups, options)[activeFilter].tableFor}
          editMethod={editItem}
          openPage={editItem}
          duplicateMethod={duplicateItem}
        />

        <Paginator
          click={handlePageClick}
          totalPages={
            tableData(data, foodGroups, options)[activeFilter].pagination
              ?.totalPages || 1
          }
          selected={currentPage - 1}
          hasNext={
            tableData(data, foodGroups, options)[activeFilter].pagination
              ?.hasNext || false
          }
        />
      </div>

      <Drawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        closeDrawer={() => {
          navigate(-1);
        }}
      >
        <CreateFoodItem closeDrawer={() => navigate(-1)} />
      </Drawer>

      <Modal
        isOpen={modal !== 0}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[630px] default-modal`}
        overlayClassName="modal-overlay"
      >
        {(modal === 1 || modal === 2) && (
          <AddFoodGroup
            closeModal={closeModal}
            duplicate={modal === 2}
            group={editGroup}
          />
        )}
        {(modal === 3 || modal === 4) && (
          <AddOption
            duplicate={modal === 4}
            editOption={editOption}
            toggle={closeModal}
          />
        )}
      </Modal>
    </>
  );
};

export default FoodItemsScreen;
