import {
  foodItemServices,
  foodItemStatus,
  foodItemType,
} from '../../../../../constants';
import { Services } from '../../../../../types';
import {
  AddButton,
  ImgInput,
  PrimaryInput,
  PrimarySelect,
  SelectInput,
  TextArea,
} from '../../../../components';
import { ImageUploader } from '../../../../components/image-uploader';
import { FoodItemDetailsProps } from '../types';
import { FoodItemImage } from './food-item-image';

export const FoodItemDetails = ({
  newImage,
  payload,
  images,
  brands,
  categories,
  setImages,
  setPayload,
  setNewImage,
  onChangeBrand,
  onChangeService,
  updateImageUrl,
  onGetInputValue,
  onChangeCategories,
}: FoodItemDetailsProps) => {
  return (
    <div>
      <div className="relative">
        <p className="bold">Food item Images</p>
        <div className="flex items-end space-x-1 mt-2">
          <p className="text-xs text-gray-600 uppercase">Upload type:</p>
          <button
            onClick={() => setNewImage((prev) => !prev)}
            className="uppercase bold text-primary-700 text-xs z-10"
            type="button"
          >
            {newImage ? 'Upload new image' : 'Input image URL'} &#8595;
          </button>
        </div>

        {!newImage ? (
          <ImgInput
            name="imageUrl"
            label="Food item primary image"
            containerClass="w-full"
            value={payload.imageUrl ?? ''}
            onChange={onGetInputValue}
          />
        ) : (
          <div className="mt-7">
            <ImageUploader
              name="imageUrl"
              getUpload={(val) => setPayload({ ...payload, imageUrl: val })}
            />
          </div>
        )}
      </div>

      <div className="mt-6">
        <p className="bold text-sm">Add more images</p>

        {images.map((image, index) => (
          <FoodItemImage
            key={index}
            index={index}
            image={image}
            images={images}
            setImages={setImages}
            updateImageUrl={updateImageUrl}
          />
        ))}

        <AddButton
          onClick={() => setImages([...images, ''])}
          text="Add an image"
        />
      </div>

      <PrimaryInput
        onChange={onGetInputValue}
        value={payload?.name}
        placeholder="Enter item name"
        required
        name="name"
        label="What is the name of the food item?"
        className="w-full"
      />

      <TextArea
        onChange={onGetInputValue}
        value={payload?.description ?? ''}
        placeholder="Enter item description"
        name="description"
        label="Describe the food item"
        className="w-full col-span-2"
      />

      <PrimarySelect
        onChange={onGetInputValue}
        options={foodItemType}
        optionLabel="label"
        value={payload.comboItem ? 1 : 0}
        optionValue="value"
        label="Food item type"
        name="comboItem"
        placeholder="Select food item type"
        className="w-full"
      />

      <SelectInput
        name="categories"
        label="What categories can you find this food item in?"
        placeholder="Select categories"
        options={categories ?? []}
        handleChange={onChangeCategories}
        getOptionValue={(item) => `${item.id}`}
        getOptionLabel={(item) => item.name}
        value={categories?.filter((s) => payload.categories?.includes(s.id))}
        className="z-40 relative"
      />

      <SelectInput
        singleSelect
        name="brand"
        label="What brand owns this food item?"
        placeholder="Select Brand"
        options={brands ?? []}
        handleChange={onChangeBrand}
        getOptionValue={(item) => `${item.id}`}
        getOptionLabel={(item) => item.name}
        value={brands?.find((s) => payload.brand === s.id)}
        className="z-30 relative"
      />

      <SelectInput
        name="services"
        label="What delivery services applies to this food item?"
        placeholder="Select service(s)"
        options={foodItemServices}
        handleChange={onChangeService}
        getOptionValue={(item) => `${item.value}`}
        getOptionLabel={(item) => item.label}
        value={foodItemServices.filter((s) =>
          payload.services.includes(s.value as Services)
        )}
        className="z-20 relative"
      />

      <PrimaryInput
        onChange={onGetInputValue}
        value={`${payload?.price}`}
        placeholder="Enter item price"
        required
        name="price"
        label="What is the price of the food item?"
        className="w-full"
        type="number"
      />

      <PrimarySelect
        onChange={onGetInputValue}
        options={foodItemStatus}
        optionLabel="label"
        value={payload.status}
        optionValue="value"
        label="Food item status"
        name="status"
        placeholder="Select status"
        className="w-full"
      />
    </div>
  );
};
