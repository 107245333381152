import { DrawerProps } from './types';

export const Drawer = ({
  children,
  openDrawer,
  setOpenDrawer,
  buttonChildren,
  closeDrawer,
}: DrawerProps) => {
  return (
    <>
      {openDrawer && (
        <div
          className="fixed inset-0 z-10 bg-black bg-opacity-10"
          onClick={closeDrawer}
        />
      )}

      <div
        className={`right-0 top-0 z-10 flex h-full flex-col justify-between overflow-y-auto overflow-x-visible bg-contain transition duration-500 absolute w-full sm:w-8/12 sm:px-4 sm:py-2 lg:w-7/12 xl:w-5/12 xl:col-span-4 bg-white ${
          openDrawer ? '' : 'translate-x-full hidden'
        }`}
      >
        {children}
      </div>

      {buttonChildren && (
        <button
          className={`absolute bottom-10 transition-all duration-500 ${
            openDrawer ? 'right-[66%] lg:right-[57%]' : 'right-0'
          } z-10 hidden items-center rounded-l-lg border bg-white px-4 py-3 text-sm sm:flex`}
          onClick={() => setOpenDrawer((prev: boolean) => !prev)}
        >
          {buttonChildren}
        </button>
      )}
    </>
  );
};
