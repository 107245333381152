import moment from 'moment';
import { Cell } from 'react-table';

const promotionStatus = [
  { name: 'Yes', value: 1 },
  { name: 'No', value: 0 },
];

const promotionsColumn = [
  {
    Header: 'Promotion Title',
    accessor: 'title',
  },
  {
    Header: 'Promotion status',
    accessor: 'isActive',
    Cell: ({ row }: Cell) => (row.values.isActive ? 'Active' : 'Inactive'),
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
    Cell: ({ row }: Cell) =>
      moment(row.values.startDate).format('Do MMMM YYYY, H:mm'),
  },
  {
    Header: 'Deadline',
    accessor: 'deadline',
    Cell: ({ row }: Cell) =>
      moment(row.values.deadline).format('Do MMMM YYYY, H:mm'),
  },
  {
    Header: 'Action',
  },
];

export { promotionStatus, promotionsColumn };
