import React from 'react';
import { chevronDown } from '../../../assets/svg';

export const ColorPicker = ({
  activeOption,
  setActiveOption,
}: {
  activeOption?: string | null;
  setActiveOption: (x: string) => void;
}) => {
  const colors = [
    '#B5E1A8',
    '#E1CDA8',
    '#A8E1E1',
    '#A8C7E1',
    '#C0A8E1',
    '#E1A8CA',
    '#FFB5A8',
    '#A8EFFF',
  ];
  const [showOptions, setShowOptions] = React.useState(false);

  return (
    <>
      {showOptions && (
        <div
          onClick={() => setShowOptions(false)}
          className="bg-black bg-opacity-20 fixed -left-2 inset-0 w-full h-full z-20"
        />
      )}

      <div className="relative">
        <button
          type="button"
          onClick={() => setShowOptions(true)}
          className="border rounded border-neutral-400 h-14 flex items-center space-x-2 p-3"
        >
          <div
            style={{
              backgroundColor: activeOption ?? 'transparent',
            }}
            className={`rounded-full w-10 h-10 flex-shrink-0`}
          />
          <img src={chevronDown} className="h-2" alt="chevron" />
        </button>

        {showOptions && (
          <div className="absolute z-20 top-16 p-3 w-[200px] right-0 rounded border bg-white">
            <p>Pick a color</p>
            <div className="grid grid-cols-4 gap-0.5">
              {colors.map((c) => (
                <button
                  key={c}
                  type="button"
                  style={{
                    backgroundColor: c,
                  }}
                  className={`rounded-full w-10 h-10 border ${
                    activeOption === c
                      ? 'border-neutral-400'
                      : 'border-transparent'
                  }`}
                  onClick={() => {
                    setActiveOption(c);
                    setShowOptions(false);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
