import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useFetch } from './useFetch';
import React from 'react';
import {
  CreateFoodItem,
  CreateFoodItemDiscount,
  CustomFoodGroup,
  FoodGroup,
  FoodItem,
  IBrand,
  ICategory,
  ResolvedResponse,
  Services,
} from '../types';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';

export const useFoodItems = () => {
  const navigate = useNavigate();
  let { action, id } = useParams();
  const [searchParams] = useSearchParams();
  const duplicate = searchParams.get('duplicate');
  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const {
    useFetchBrand,
    useFetchCategory,
    useFetchFoodGroup,
    useFetchFoodItem,
  } = useFetch();

  const { data: brands, isLoading: brandsLoading } = useFetchBrand<IBrand[]>();

  const { data: categories, isLoading: categoriesLoading } =
    useFetchCategory<ICategory[]>();

  const { data: foodGroups, isLoading: groupLoading } = useFetchFoodGroup<
    ResolvedResponse<FoodGroup[]>
  >(undefined, { count: 500 });

  const { data: foodItem, isLoading: foodItemLoading } = useFetchFoodItem<
    ResolvedResponse<FoodItem>
  >(duplicate ?? id, undefined, {
    enabled: !!id || !!duplicate,
  });

  const [page, setPage] = React.useState(1);
  const [modal, setModal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [newImage, setNewImage] = React.useState(true);
  const [required, setRequired] = React.useState(false);
  const [images, setImages] = React.useState<string[]>([]);
  const [editGroup, setEditGroup] = React.useState<string>();
  const [discountOption, setDiscountOption] = React.useState<
    'percent' | 'amount'
  >('percent');
  const [payload, setPayload] = React.useState<CreateFoodItem>({
    name: '',
    price: 0,
    status: 'OPEN',
    comboItem: false,
    imageUrl: '',
    services: [],
    foodGroups: [],
  });
  const [discountPayload, setDiscountPayload] =
    React.useState<CreateFoodItemDiscount>({
      foodItemId: '',
      enabled: false,
      rounding: 10,
    });

  const reorderGroups = async (
    group: string,
    list: CustomFoodGroup[],
    endIndex: number
  ) => {
    const result = [...list];

    let draggedGroup = result.find((g) => g.id === group);
    if (draggedGroup) {
      draggedGroup.position = endIndex;
      await mealsApi.updateFoodGroup(draggedGroup.id, draggedGroup);
    }
  };

  const setGroupForEditing = (groupId: string) => {
    if (groupId) {
      setEditGroup(groupId);
      return setModal(1);
    }
  };

  const closeModal = () => {
    setEditGroup(undefined);
    setModal(0);
  };

  const selectedGroups = foodGroups?.data?.filter((g) =>
    payload.foodGroups?.some((item1) => item1.id === g.id)
  );

  const selectedCustomizations = foodGroups?.data?.filter((g) =>
    payload.customisations?.some((item1) => item1.id === g.id)
  );

  const goBack = (closeDrawer?: () => void) => {
    if (page === 1 && closeDrawer) {
      return closeDrawer();
    }

    setPage((prev) => prev - 1);
  };

  const onGetInputValue = (
    event: React.SyntheticEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'comboItem') {
      return setPayload({
        ...payload,
        comboItem: parseInt(value) > 0 ? true : false,
      });
    }

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const onGetDiscountInputValue = (
    event: React.SyntheticEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'type') {
      setDiscountOption(value as 'percent' | 'amount');

      return setDiscountPayload({
        ...discountPayload,
        percent: 0,
        flat: 0,
      });
    }

    if (name === 'amount') {
      return setDiscountPayload({
        ...discountPayload,
        [discountOption === 'amount' ? 'flat' : 'percent']: parseInt(value),
      });
    }

    if (name === 'enabled') {
      return setDiscountPayload({
        ...discountPayload,
        enabled: parseInt(value) > 0 ? true : false,
      });
    }

    if (name === 'brandId') {
      return setDiscountPayload({
        ...discountPayload,
        brandId: parseInt(value) > 0 ? foodItem?.data?.brand?.id : null,
      });
    }

    setDiscountPayload({
      ...discountPayload,
      [name]: parseInt(value),
    });
  };

  const onChangeBrand: IOnChange<IBrand> = (brand) => {
    if (brand) setPayload({ ...payload, brand: (brand as IBrand).id });
  };

  const onChangeCategories: IOnChange<ICategory> = (category) => {
    if (category)
      setPayload({
        ...payload,
        categories: (category as ICategory[]).map((c) => c.id),
      });
  };

  const onChangeCustomization: IOnChange<FoodGroup> = (group) => {
    if (group)
      setPayload({
        ...payload,
        customisations: (group as FoodGroup[]).map((s) => {
          return {
            id: s.id!,
            position: s.position,
            name: s.name,
            identifier: s.identifier ?? '',
          };
        }),
      });
  };

  const onChangeService: IOnChange<{ label: string; value: string }> = (
    services
  ) => {
    setPayload({
      ...payload,
      services: (services as { label: string; value: string }[]).map(
        (s) => s.value
      ) as Services[],
    });
  };

  const onChangeFoodGroup: IOnChange<FoodGroup> = (group) => {
    setPayload({
      ...payload,
      foodGroups: (group as FoodGroup[]).map((s) => {
        return {
          id: s.id!,
          position: s.position,
          name: s.name,
          identifier: s.identifier ?? '',
        };
      }),
    });
  };

  const setCreatedGroup = (group: FoodGroup, section: number) => {
    const tempPayload = { ...payload };

    tempPayload[section === 1 ? 'foodGroups' : 'customisations']?.push({
      id: group.id!,
      position: group.position,
      name: group.name,
      identifier: group.identifier ?? '',
    });

    setPayload(tempPayload);
  };

  const removeFoodGroup = (group: CustomFoodGroup) => {
    const tempFoodGroups = [...payload.foodGroups];

    const updatedFoodGroups = tempFoodGroups.filter((g) => g.id !== group.id);

    setPayload({
      ...payload,
      foodGroups: updatedFoodGroups,
    });
  };

  const removeCustomization = (group: CustomFoodGroup) => {
    const tempFoodGroups = [...(payload?.customisations ?? [])];

    const updatedFoodGroups = tempFoodGroups.filter((g) => g.id !== group.id);

    setPayload({
      ...payload,
      customisations: updatedFoodGroups,
    });
  };

  const updateImageUrl = (x: string, index: number) => {
    setImages((prevItems) =>
      prevItems.map((item, i) => (i === index ? x : item))
    );
  };

  const createFoodItem = async (e?: React.SyntheticEvent<HTMLFormElement>) => {
    e?.stopPropagation();
    e?.preventDefault();
    setLoading(true);

    if (!required) return setLoading(false);

    if (!loading) {
      try {
        const { foodGroups, customisations, ...rest } = payload;
        const finalPayload = {
          ...rest,
          images,
          foodGroups: foodGroups.map((g) => g.id),
          customisations: customisations?.map((g) => g.id),
        };
        if (id) {
          await mealsApi.updateFoodItem(id, finalPayload);

          if (foodItem) {
            if (foodItem.data?.discounts) {
              await mealsApi.updateItemDiscount(
                foodItem.data?.discounts.id,
                discountPayload
              );
            } else if (discountPayload.enabled) {
              await mealsApi.createDiscount(discountPayload);
            }
          }

          toast.success('Food item updated!');
        } else {
          await mealsApi.createFoodItem(finalPayload);

          toast.success('Food item created!');
        }

        queryClient.refetchQueries(['food-items']);
        navigate('/dashboard/food-items');
      } catch (error) {
        toast.error(parseError(error));
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    const { name, price, brand } = payload;

    setRequired(name.length > 0 && price > 0 && !!brand);
  }, [payload]);

  React.useEffect(() => {
    if (foodItem && foodItem.data && (id || duplicate)) {
      const item = foodItem.data;

      setPayload({
        name: duplicate ? `${item.name} copy` : item.name,
        services: item.services,
        status: item.status,
        price: item.price,
        comboItem: item.comboItem,
        description: item.description,
        brand: item.brand?.id,
        imageUrl: item.imageUrl,
        categories: item.categories?.map((c) => c.id),
        customisations: (item.customisations as FoodGroup[])?.map((i) => {
          return {
            id: i.id!,
            position: i.position,
            name: i.name,
            identifier: i.identifier ?? '',
          };
        }),
        foodGroups: (item.foodGroup as FoodGroup[])?.map((i) => {
          return {
            id: i.id!,
            position: i.position,
            name: i.name,
            identifier: i.identifier ?? '',
          };
        }),
      });

      if (item.imageUrl) {
        setNewImage(false);
      }

      setDiscountOption(
        item.discounts
          ? item.discounts?.flat
            ? 'amount'
            : 'percent'
          : 'amount'
      );

      setImages(item.images ?? []);

      setDiscountPayload({
        foodItemId: item.id,
        percent: item.discounts?.percent,
        enabled: item.discounts?.enabled ?? false,
        flat: item.discounts?.flat ?? 0,
        rounding: item.discounts?.rounding,
        brandId: item.discounts?.brand?.id,
      });
    }
  }, [foodItem, id, duplicate]);

  React.useEffect(() => {
    if (pathname.includes('/create')) {
      setPayload({
        name: '',
        price: 0,
        status: 'OPEN',
        comboItem: false,
        imageUrl: '',
        services: [],
        foodGroups: [],
      });

      setImages([]);
      setNewImage(true);
      setDiscountPayload({
        foodItemId: '',
        enabled: false,
        rounding: 10,
      });
    }

    setPage(1);
  }, [pathname]);

  return {
    id,
    page,
    modal,
    brands,
    action,
    images,
    loading,
    payload,
    required,
    foodItem,
    newImage,
    duplicate,
    editGroup,
    categories,
    foodGroups,
    groupLoading,
    brandsLoading,
    selectedGroups,
    discountOption,
    foodItemLoading,
    discountPayload,
    categoriesLoading,
    selectedCustomizations,
    goBack,
    setPage,
    navigate,
    setImages,
    setModal,
    closeModal,
    setPayload,
    setNewImage,
    onChangeBrand,
    reorderGroups,
    updateImageUrl,
    createFoodItem,
    setCreatedGroup,
    removeFoodGroup,
    onGetInputValue,
    onChangeService,
    onChangeFoodGroup,
    setGroupForEditing,
    onChangeCategories,
    removeCustomization,
    onChangeCustomization,
    onGetDiscountInputValue,
  };
};
