import { Cell, Row } from 'react-table';
import {
  IKitchen,
  IOrder,
  IPilot,
  PilotKyc,
  PilotSummary,
  PilotVehicle,
} from '../types';
import { returnRiderStatus } from '../helpers/riders.helpers';

const ridersColumn = [
  {
    Header: 'Pilot Name',
    accessor: 'user',
    Cell: ({ row }: Cell) =>
      `${row.values?.user?.firstName ?? ''} ${
        row.values?.user?.lastName ?? ''
      }`,
  },
  {
    Header: 'Pilot Realtime Status',
    sortType: (rowA: Row<IPilot>, rowB: Row<IPilot>, _columnId: string) => {
      const statusA = returnRiderStatus(rowA.values.business);
      const statusB = returnRiderStatus(rowB.values.business);
      const statusOrder = ['ONLINE', 'OFFLINE'];
      return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
    },
    accessor: 'business',
    Cell: ({ row }: Cell) => (
      <p
        className={`bold ${
          returnRiderStatus(row.values.business) === 'ONLINE'
            ? 'text-success-800'
            : 'text-primary-700'
        }`}
      >
        {returnRiderStatus(row.values.business)}
      </p>
    ),
  },
  {
    Header: 'Pilot vehicle type',
    accessor: 'vehicles',
    sortType: (rowA: Row<IPilot>, rowB: Row<IPilot>, _columnId: string) => {
      const statusA = rowA.values?.vehicles.length
        ? rowA.values?.vehicles[0].type
        : '-';
      const statusB = rowB.values?.vehicles.length
        ? rowB.values?.vehicles[0].type
        : '-';
      const statusOrder = ['BIKE', '-', 'BICYCLE'];
      return statusOrder.indexOf(statusA) - statusOrder.indexOf(statusB);
    },
    Cell: ({ row }: Cell) =>
      row.values?.vehicles.length ? row.values?.vehicles[0].type : '-',
  },
  {
    Header: 'Pilot Kitchen',
    accessor: 'activeKitchens',

    Cell: ({ row }: Cell) =>
      `${
        row.values?.activeKitchens?.length
          ? row.values?.activeKitchens.map((k: IKitchen) => k.name).join(', ')
          : '-'
      }`,
  },

  {
    Header: 'Pilot status',
    accessor: 'status',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
  {
    Header: 'Quick Actions',
    accessor: 'updatedAt',
  },
];

const pilotDetails = (pilot: IPilot) => [
  {
    title: 'Pilot Name',
    content: `${pilot?.user.firstName} ${pilot?.user.lastName}`,
  },
  { title: 'Phone Number', content: pilot?.user.phoneNumber },
  { title: 'Email', content: pilot?.user.email },
  {
    title: 'Average Delivery Time',
    content: `${pilot?.averageDeliveryTime.toString()} minutes`,
  },
  {
    title: 'Status',
    content: pilot?.status,
  },
  {
    title: 'Employment Type',
    content: pilot?.employmentType,
  },
];

export const kycDetails = (kyc: PilotKyc) => [
  {
    title: 'Status',
    content: `${kyc.status}`,
  },
];

const hiddenBankKeys = ['bankCode', 'id', 'kitchenId', 'partnerId', 'pilotId'];

export const hiddenVehiclesKeys: (keyof PilotVehicle)[] = [
  'createdAt',
  'id',
  'pilotId',
  'updatedAt',
  'isElectric',
];

const formatBankKeys = {
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  bankName: 'Bank Name',
};

export const formatVehicleKeys = {
  id: 'ID',
  createdAt: 'Date Created',
  updatedAt: 'Date Updated',
  pilotId: 'Pilot',
  status: 'Status',
  type: 'Type',
  make: 'Make',
  plateNumber: 'Plate Number',
  isElectric: 'Is Electric',
};

export const pilotInfoCards = (orders: IOrder[]) => [
  {
    title: 'Orders Delivered',
    value: orders.filter((order) => order.status === 'DELIVERED').length,
    bg: 'orange-gradient',
  },
  {
    title: 'Orders Enroute',
    value: orders.filter((order) => order.status === 'ENROUTE').length,
    bg: 'blue-gradient',
  },
  {
    title: 'Total Earnings',
    value: `₦${orders
      .filter((item) => item.status === 'DELIVERED')
      .reduce((sum, a) => sum + a.deliveryCharge, 0)}`,
    bg: 'purple-gradient',
  },
];

export const vehicleType = [
  { label: 'BIKE', value: 'BIKE' },
  { label: 'BICYCLE', value: 'BICYCLE' },
  { label: 'CAR', value: 'CAR' },
  { label: 'SCOOTER', value: 'SCOOTER' },
  { label: 'OTHER', value: 'OTHER' },
];

export const riderPartnerStatus = [
  { name: 'Active', value: 'ACTIVE' },
  { name: 'Inactive', value: 'INACTIVE' },
  { name: 'Closed', value: 'CLOSED' },
  { name: 'Flagged', value: 'FLAGGED' },
];

export const pilotCountCards = (summary?: PilotSummary) => [
  {
    title: 'Total Bike Riders',
    value: summary?.totalBikes,
    bg: 'orange-gradient',
  },
  {
    title: 'Online Bike Riders',
    value: summary?.onlineBikes,
    bg: 'blue-gradient',
  },
  {
    title: 'Total Bicycle Riders',
    value: summary?.totalBicycles,
    bg: 'orange-gradient',
  },
  {
    title: 'Online Bicycle Riders',
    value: summary?.onlineBicycles,
    bg: 'blue-gradient',
  },
];

export const broadcastRecipients = [
  { name: 'All', value: 'ALL' },
  { name: 'Kitchen', value: 'KITCHEN' },
  { name: 'Riders', value: 'RIDERS' },
];

export { ridersColumn, hiddenBankKeys, formatBankKeys, pilotDetails };
