import Modal from 'react-modal';
import { backArrow } from '../../../../../assets/svg';
import {
  couponStatus,
  discountBrandOption,
  discountType,
} from '../../../../../constants';
import { useFoodItems } from '../../../../../hooks';
import {
  DraggableSort,
  Loading,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
  SelectInput,
} from '../../../../components';
import { FoodItemDetails } from './food-item-details';
import { AddFoodGroup } from './add-food-group';

const CreateFoodItem = ({ closeDrawer }: { closeDrawer: () => void }) => {
  const {
    id,
    page,
    modal,
    action,
    brands,
    images,
    payload,
    loading,
    required,
    newImage,
    duplicate,
    editGroup,
    categories,
    foodGroups,
    groupLoading,
    brandsLoading,
    selectedGroups,
    foodItemLoading,
    discountOption,
    discountPayload,
    categoriesLoading,
    selectedCustomizations,
    goBack,
    setPage,
    setModal,
    setImages,
    closeModal,
    setPayload,
    setNewImage,
    onChangeBrand,
    reorderGroups,
    updateImageUrl,
    createFoodItem,
    onChangeService,
    onGetInputValue,
    setCreatedGroup,
    removeFoodGroup,
    onChangeFoodGroup,
    setGroupForEditing,
    onChangeCategories,
    removeCustomization,
    onChangeCustomization,
    onGetDiscountInputValue,
  } = useFoodItems();

  return (
    <div>
      {(brandsLoading ||
        groupLoading ||
        categoriesLoading ||
        (id || duplicate ? foodItemLoading : false)) && <Loading />}

      <div className="px-4 lg:px-8 py-8 w-full">
        <div className="flex justify-between">
          <div className="flex items-center">
            {(action === 'create' ? [1, 2, 3] : [1, 2, 3, 4]).map((num) => (
              <PageIdentifier
                num={num}
                key={num}
                page={page}
                numOfPages={action === 'create' ? 3 : 4}
                setPage={setPage}
              />
            ))}
          </div>
          <button
            className="flex items-center space-x-2 text-sm"
            onClick={closeDrawer}
          >
            <img src={backArrow} alt="back" />
            <span className="semibold">Close</span>
          </button>
        </div>

        <div className="mt-4">
          <div className="bg-white mt-2.5">
            {page === 1 && (
              <FoodItemDetails
                newImage={newImage}
                payload={payload}
                images={images}
                brands={brands}
                categories={categories}
                setImages={setImages}
                setPayload={setPayload}
                setNewImage={setNewImage}
                onChangeBrand={onChangeBrand}
                onChangeService={onChangeService}
                updateImageUrl={updateImageUrl}
                onGetInputValue={onGetInputValue}
                onChangeCategories={onChangeCategories}
              />
            )}

            {page === 2 && (
              <div className="relative">
                <p>Food Groups</p>

                <DraggableSort
                  itemKey={(i) => i.id}
                  onRemoveItem={removeFoodGroup}
                  list={payload.foodGroups ?? []}
                  itemName={(i) =>
                    `${i.name}${i.identifier ? ` - ${i.identifier}` : ''}`
                  }
                  editMethod={(g) => setGroupForEditing(g.id)}
                  reOrderMethod={(i, list, endIndex) =>
                    reorderGroups(i, list, endIndex)
                  }
                  setState={(i) =>
                    setPayload({
                      ...payload,
                      foodGroups: i,
                    })
                  }
                />

                <SelectInput
                  name="foodGroups"
                  label="Select an existing food group"
                  placeholder="Select food groups"
                  options={foodGroups?.data ?? []}
                  handleChange={onChangeFoodGroup}
                  getOptionValue={(item) => `${item.id}`}
                  getOptionLabel={(item) =>
                    `${item.name}${
                      item.identifier ? ` - ${item.identifier}` : ''
                    }`
                  }
                  value={selectedGroups}
                  className="z-30 relative"
                />

                <div className="flex items-center space-x-3">
                  <div className="h-px flex-grow bg-neutral-300" />
                  <p className="text-sm">or</p>
                  <div className="h-px flex-grow bg-neutral-300" />
                </div>

                <button
                  type="button"
                  onClick={() => setModal(1)}
                  className="w-full border border-neutral-400 rounded text-center py-2 px-4 mt-2"
                >
                  Create a new food group
                </button>
              </div>
            )}

            {page === 3 && (
              <div className="relative">
                <p>Customizations</p>

                <DraggableSort
                  itemKey={(i) => i.id}
                  onRemoveItem={removeCustomization}
                  list={payload.customisations ?? []}
                  itemName={(i) =>
                    `${i.name}${i.identifier ? ` - ${i.identifier}` : ''}`
                  }
                  editMethod={(g) => setGroupForEditing(g.id)}
                  reOrderMethod={(i, list, endIndex) =>
                    reorderGroups(i, list, endIndex)
                  }
                  setState={(i) =>
                    setPayload({
                      ...payload,
                      foodGroups: i,
                    })
                  }
                />
                <SelectInput
                  name="customisations"
                  label="What customizations can be applied to this food item?"
                  placeholder="Select customizations"
                  options={foodGroups?.data ?? []}
                  handleChange={onChangeCustomization}
                  getOptionValue={(item) => `${item.id}`}
                  getOptionLabel={(item) => item.name ?? ''}
                  value={selectedCustomizations}
                  className="z-20 relative"
                />

                <div className="flex items-center space-x-3">
                  <div className="h-px flex-grow bg-neutral-300" />
                  <p className="text-sm">or</p>
                  <div className="h-px flex-grow bg-neutral-300" />
                </div>

                <button
                  type="button"
                  onClick={() => setModal(2)}
                  className="w-full border border-neutral-400 rounded text-center py-2 px-4 mt-2"
                >
                  Create a new food group
                </button>
              </div>
            )}

            {page === 4 && action !== 'create' && (
              <div>
                <p className="bold">Discounts</p>
                <p className="uppercase text-primary-700 text-xs bold">
                  add a discount to this item &#8595;
                </p>

                <div className="grid sm:grid-cols-2 gap-4 w-full">
                  <PrimarySelect
                    value={discountOption}
                    onChange={onGetDiscountInputValue}
                    options={discountType}
                    optionLabel="label"
                    optionValue="value"
                    label="Discount Type"
                    name="type"
                    placeholder="Select type"
                    className="w-full"
                  />

                  <PrimaryInput
                    onChange={onGetDiscountInputValue}
                    value={
                      discountOption === 'amount'
                        ? `${discountPayload.flat}`
                        : `${discountPayload.percent}`
                    }
                    placeholder="Enter Discount Amount"
                    name="amount"
                    label="Discount Amount"
                    type="number"
                    max={discountOption === 'percent' ? 100 : undefined}
                    className="w-full"
                  />

                  <PrimaryInput
                    onChange={onGetDiscountInputValue}
                    value={`${discountPayload.rounding}`}
                    placeholder="Enter rounding (approximation value)"
                    name="rounding"
                    label="Rounding"
                    type="number"
                    className="w-full"
                  />

                  <PrimarySelect
                    value={discountPayload?.enabled ? '1' : '0'}
                    onChange={onGetDiscountInputValue}
                    options={couponStatus}
                    optionLabel="label"
                    optionValue="value"
                    label="Discount Status"
                    name="enabled"
                    placeholder="Select status"
                    className="w-full"
                  />

                  {payload.brand && (
                    <PrimarySelect
                      value={discountPayload?.brandId ? '1' : '0'}
                      onChange={onGetDiscountInputValue}
                      options={discountBrandOption}
                      optionLabel="label"
                      optionValue="value"
                      label="Do you want to apply the discount to the full brand?"
                      name="brandId"
                      placeholder="Do you want to apply the discount to the full brand?"
                      className="w-full"
                    />
                  )}
                </div>
              </div>
            )}

            <div className="flex items-center justify-between space-x-4 mt-12">
              <SecondaryButton
                text={page === 1 ? 'Cancel' : 'Back'}
                type="button"
                onClick={() => goBack(closeDrawer)}
              />

              {(action === 'create' ? page === 3 : page === 4) ? (
                <PrimaryButton
                  loading={loading}
                  onClick={createFoodItem}
                  disabled={!required || loading}
                  text={`${id ? 'Update' : 'Create'} food item`}
                />
              ) : (
                <>
                  <PrimaryButton
                    loading={loading}
                    onClick={createFoodItem}
                    disabled={!required || loading}
                    text={`${id ? 'Update' : 'Create'} food item`}
                    type="submit"
                  />

                  <SecondaryButton
                    text="Next page"
                    type="button"
                    onClick={() => setPage((prev) => prev + 1)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal !== 0}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[630px] default-modal`}
        overlayClassName="modal-overlay"
      >
        <AddFoodGroup
          callback={(g) => setCreatedGroup(g, modal)}
          closeModal={closeModal}
          group={editGroup}
        />
      </Modal>
    </div>
  );
};

export default CreateFoodItem;

const PageIdentifier = ({
  num,
  page,
  numOfPages,
  setPage,
}: {
  num: number;
  page: number;
  numOfPages: number;
  setPage: (x: number) => void;
}) => {
  const isActive = page === num;
  const isDone = page > num;

  return (
    <div key={num} className="flex items-center">
      <button
        type="button"
        onClick={() => setPage(num)}
        className={`border text-xs h-6 w-6 rounded-full ${
          isActive
            ? 'border-primary-100 bg-primary-50 text-primary-300'
            : isDone
            ? 'border-primary-300 bg-primary-300 text-primary-50'
            : 'border-neutral-50 bg-neutral-100 text-neutral-600'
        }`}
      >
        {num}
      </button>{' '}
      {num !== numOfPages && <div className="w-8 h-1 bg-neutral-100" />}
    </div>
  );
};
