import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { close, hamburger } from '../../../assets/svg';
import { DraggableSortProps } from './types';

export const DraggableSort = <T,>({
  list,
  offset,
  itemKey,
  itemName,
  setState,
  editMethod,
  onRemoveItem,
  reOrderMethod,
}: DraggableSortProps<T>) => {
  const reorder = (
    list: T[],
    startIndex: number,
    endIndex: number,
    group: string
  ) => {
    const result = [...list];

    reOrderMethod(group, result, endIndex);

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(
      list ?? [],
      result.source.index,
      result.destination.index,
      result.draggableId
    );

    setState(items);
  };
  const getItemStyle = (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle
  ) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none' as any,
    padding: 6 * 2,
    margin: `0 0 ${6}px 0`,

    // change background colour if dragging
    background: '#EBE8E6',
    boxShadow: isDragging ? `rgba(0, 0, 0, 0.3) 0px 10px 20px 0px` : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    padding: 6,
    width: '100%',
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {list?.map((item, index) => (
              <Draggable
                key={`${itemKey(item)}`}
                draggableId={`${itemKey(item)}`}
                index={index}
              >
                {(provided, snapshot) => {
                  if (snapshot.isDragging && offset?.x && offset.y) {
                    const x =
                      (provided.draggableProps.style as DraggingStyle).left -
                      offset.x;
                    const y =
                      (provided.draggableProps.style as DraggingStyle).top -
                      offset.y;
                    (provided.draggableProps.style as DraggingStyle).left = x;
                    (provided.draggableProps.style as DraggingStyle).top = y;
                  }

                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <div className="flex items-center justify-between space-x-4">
                        <img src={hamburger} alt="hamburger" />
                        <div className="flex items-center space-x-2">
                          <p>{itemName(item)}</p>
                          {editMethod && (
                            <button
                              type="button"
                              onClick={() => editMethod(item)}
                              className="px-1 py-0.5 bg-primary-brown text-white text-sm rounded"
                            >
                              edit
                            </button>
                          )}
                        </div>
                        {onRemoveItem && (
                          <button onClick={() => onRemoveItem(item)}>
                            <img className="h-3" src={close} alt="close" />
                          </button>
                        )}
                      </div>
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
