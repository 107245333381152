import numeral from 'numeral';
import Modal from 'react-modal';
import React from 'react';
import { RangeStatus } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  authSelector,
  getFilteredOrderSummary,
  getRevenueSummary,
  getTopBrands,
  getTopCustomers,
  getTopKitchens,
  getTopLocations,
  getTopMeals,
  getTopUsers,
} from '../../../store/slices';
import { DateRanges } from '../../../types/dashboard.types';
import { Table } from './table';
import { chevronDown, close as closeIcon } from '../../../assets/svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface ISummaryCards {
  title: string;
  value?: number;
  currency?: boolean;
}

export const SummaryCard = ({ title, value, currency }: ISummaryCards) => {
  return (
    <div className="card p-4">
      <h6 className="uppercase text-neutral-600 semibold text-xs mb-5">
        {title}
      </h6>
      <h1 className="text-black bold text-xl">
        {currency && <span className="text-sm bold">NGN</span>}{' '}
        {numeral(value).format('0,0')}
      </h1>
    </div>
  );
};

interface IDetailCards {
  title?: string;
  showCustom?: boolean;
  card?: string;
  columns?: any;
  data?: any;
  children?: (range: DateRanges) => React.ReactNode;
}

export const DetailCard = ({
  card,
  children,
  columns,
  data,
  showCustom,
}: IDetailCards) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(authSelector);
  const { admin } = user;

  const [modal, setModal] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [range, setRange] = React.useState(
    showCustom ? 'CUSTOM' : RangeStatus[0].value
  );
  const [date, setDate] = React.useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: new Date(),
    endDate: null,
  });

  const _toggleModal = () => {
    setModal((prev) => !prev);
  };

  const _toggleFilter = () => {
    setFilterOpen((prev) => !prev);
  };

  const onCalenderChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDate({ ...date, startDate: start, endDate: end });

    if (start && end) {
      fetchAnalytics({ date: { startDate: start, endDate: end } });
    }
  };

  const fetchAnalytics = ({
    e,
    predefinedDate,
    limit,
    date,
  }: {
    e?: React.ChangeEvent<HTMLSelectElement>;
    predefinedDate?: string;
    limit?: number;
    date?: { startDate: Date; endDate: Date };
  }) => {
    const filterValue = limit ? range : e ? e.currentTarget.value : 'CUSTOM';

    const params =
      filterValue === 'CUSTOM' && date?.endDate && date.startDate
        ? date
        : filterValue;

    const functionMapper = {
      revenue: getRevenueSummary({ range: params }),
      orderSummary: getFilteredOrderSummary({ range: params, limit }),
      kitchens: getTopKitchens({ range: params, limit }),
      meals: getTopMeals({
        range: params,
        limit,
        query:
          admin?.kitchens && admin?.kitchens.length
            ? admin.kitchens[0].id
            : undefined,
      }),
      brands: getTopBrands({ range: params, limit }),
      customers: getTopCustomers({
        range: params,
        limit,
        query:
          admin?.kitchens && admin?.kitchens.length
            ? admin.kitchens[0].id
            : undefined,
      }),
      locations: getTopLocations({ range: params, limit }),
      users: getTopUsers({
        range: params,
        limit,
        query:
          admin?.kitchens && admin?.kitchens.length
            ? admin.kitchens[0].id
            : undefined,
      }),
    };

    if (predefinedDate) setDate({ startDate: new Date(), endDate: null });

    if (
      (filterValue === 'CUSTOM' && date?.endDate && date.startDate) ||
      filterValue !== 'CUSTOM'
    )
      dispatch(functionMapper[card as keyof typeof functionMapper]);

    // setFilterOpen(false);
    setRange(filterValue as DateRanges);
  };

  return (
    <div className="card p-6 pb-8 relative">
      <div className="flex flex-col sm:flex-row items-center sm:items-start mb-3">
        {showCustom ? (
          <button
            className="px-4 py-2 rounded-lg bg-neutral-200 flex items-center space-x-8 relative z-20"
            onClick={_toggleFilter}
          >
            <p>
              {range === 'CUSTOM'
                ? 'Pick custom date'
                : RangeStatus.filter(({ value }) => value === range)[0].option}
            </p>
            <img src={chevronDown} alt="chevron" />
          </button>
        ) : (
          <select
            className="range-select bg-chevron-down"
            defaultValue={range}
            onChange={(e) => fetchAnalytics({ e })}
          >
            {RangeStatus.map(({ value, option }) => (
              <option key={`${card}-${value}`} value={value}>
                {option}
              </option>
            ))}
          </select>
        )}

        {filterOpen && (
          <div
            className="absolute z-30 inset-0 h-full w-full bg-black opacity-10 rounded-lg"
            onClick={_toggleFilter}
          />
        )}

        {filterOpen && (
          <div className="mt-5 sm:mt-0 sm:absolute top-[70px] left-5 flex p-2 rounded shadow border border-grey-2 bg-white space-x-2 z-30">
            <div>
              <DatePicker
                selected={date.startDate}
                onChange={onCalenderChange}
                startDate={date.startDate}
                endDate={date.endDate}
                filterDate={(date) => {
                  return new Date() > date;
                }}
                selectsRange
                inline
              />
              {date.startDate && date.endDate && (
                <div className="flex space-x-4 items-center text-xs mb-1">
                  <p className="text-center text-neutral-700">
                    {date.startDate?.toDateString()} -{' '}
                    {date.endDate?.toDateString()}
                  </p>
                  <button
                    className="underline text-primary-700"
                    onClick={() =>
                      setDate({ startDate: new Date(), endDate: null })
                    }
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>
            {/* <div className="w-4/12 space-y-1 flex flex-col">
              {RangeStatus.map((predefinedRange) => (
                <button
                  className={`px-3 py-1 rounded text-sm text-left semibold ${
                    range === predefinedRange.value
                      ? 'bg-slate-400 text-white'
                      : 'bg-slate-200'
                  }`}
                  onClick={() =>
                    fetchAnalytics({ predefinedDate: predefinedRange.value })
                  }
                  key={predefinedRange.value}
                >
                  {predefinedRange.option}
                </button>
              ))}
            </div> */}
          </div>
        )}
      </div>

      <div>{children && children(range)}</div>

      {card !== 'revenue' && card !== 'orderSummary' && data.length > 5 && (
        <div className="flex w-full justify-center absolute bottom-2 right-0">
          <button className="text-sm text-primary-700" onClick={_toggleModal}>
            View More
          </button>
        </div>
      )}

      <Modal
        isOpen={modal}
        onRequestClose={_toggleModal}
        ariaHideApp={false}
        className={`w-11/12 sm:w-8/12 h-10/12 default-modal`}
        overlayClassName="modal-overlay"
      >
        <AnalyticsTable
          toggle={_toggleModal}
          columns={columns}
          data={data}
          tableFor={card || ''}
          fetchWithCount={fetchAnalytics}
        />
      </Modal>
    </div>
  );
};

interface IAnalyticsTable {
  toggle: () => void;
  columns: any;
  data: any;
  tableFor: string;
  fetchWithCount: (params: {
    predefinedDate?: string;
    limit?: number;
    date?: { startDate: Date; endDate: Date };
  }) => void;
}

const AnalyticsTable = ({
  toggle,
  columns,
  data,
  tableFor,
  fetchWithCount,
}: IAnalyticsTable) => {
  const [limit, setLimit] = React.useState(10);

  const counts = [10, 20, 30, 40, 50];

  const handleLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    setLimit(parseInt(value));

    fetchWithCount({ limit: parseInt(value) });
  };

  React.useEffect(() => {
    fetchWithCount({ limit });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="bg-white p-6 h-full">
      <div className="flex justify-between items-center">
        <p className="bold text-2xl capitalize">top {tableFor}</p>

        <div className="space-x-10 flex items-center">
          <div className="flex space-x-2 items-center">
            <p className="text-sm medium">Amount to show:</p>
            <select
              className="range-select bg-chevron-down"
              onChange={handleLimit}
              value={limit}
            >
              {counts.map((count) => (
                <option key={`display-${count}`} value={count}>
                  {count}
                </option>
              ))}
            </select>
          </div>
          <button onClick={toggle}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>
      </div>

      <Table
        columns={columns}
        data={data}
        tableFor={tableFor}
        containerClassName="overflow-y-auto h-full mt-2 pt-3 pb-4"
      />
    </div>
  );
};
