import { useFetch } from './useFetch';
import { FoodGroup, Option, ResolvedResponse } from '../types';
import React from 'react';
import { IOnChange } from '../views/components';
import { mealsApi } from '../api';
import { toast } from 'react-toastify';
import { parseError } from '../helpers';
import { useQueryClient } from '@tanstack/react-query';

export const useFoodGroups = (
  closeModal: () => void,
  group?: string,
  duplicate?: boolean,
  callback?: (x: FoodGroup) => void
) => {
  const [modal, setModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<Option[]>([]);
  const [editOption, setEditOption] = React.useState<Option>();
  const [payload, setPayload] = React.useState<FoodGroup>({
    name: '',
    colorIdentifier: '',
    identifier: '',
    maximumSelection: null,
    minimumSelection: null,
    position: 0,
    options: [],
  });

  const queryClient = useQueryClient();

  const { useFetchOptions, useFetchFoodGroup } = useFetch();
  const { data: optionList } = useFetchOptions({
    count: 1000,
  });
  const { data: foodGroup, isLoading: foodGroupLoading } = useFetchFoodGroup<
    ResolvedResponse<FoodGroup>
  >(
    group,
    {},
    {
      enabled: !!group,
    }
  );

  const onGetInputValue = (
    event: React.SyntheticEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    setPayload({
      ...payload,
      [name]: value,
    });
  };

  const setCreatedOption = (option: Option) => {
    const tempOptions = [...options];

    tempOptions.push(option);
    setOptions(tempOptions);
  };

  const handleChangeOptions: IOnChange<Option> = (item) => {
    setOptions([...(item as Option[])]);
  };

  const processGroup = async (closeOnComplete = true) => {
    setLoading(true);

    try {
      const { foodItem, customisations, ...rest } = payload;

      if (group && !duplicate && payload.id) {
        const finalPayload = {
          ...rest,
          options: options.map((o) => o.id!),
        };

        await mealsApi.updateFoodGroup(payload.id, finalPayload);
        toast.success('Food group updated!');
      } else {
        const finalPayload = { ...rest, options };

        const createdGroup = await mealsApi.createFoodGroup(finalPayload);

        if (callback && createdGroup && createdGroup.data) {
          callback(createdGroup.data.data);
        }

        toast.success('Food item created!');
      }

      queryClient.refetchQueries(['food-group']);
      queryClient.refetchQueries(['food-items']);

      if (closeOnComplete) {
        closeModal();
      }
    } catch (error) {
      toast.error(parseError(error));
    } finally {
      setLoading(false);
    }
  };

  const createEditOptionCallback = () => {
    setModal((prev) => !prev);

    if (editOption) {
      queryClient.refetchQueries(['food-group', group]);
    }
  };

  const openModal = (option?: Option) => {
    if (option) {
      setEditOption(option);
    }

    processGroup(false);
    setModal(true);
  };

  React.useEffect(() => {
    if (foodGroup && foodGroup.data) {
      setPayload({
        ...foodGroup.data,
        name: duplicate ? `${foodGroup.data.name} copy` : foodGroup.data.name,
      });

      setOptions(foodGroup.data?.options ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodGroup]);

  return {
    modal,
    options,
    payload,
    loading,
    foodGroup,
    editOption,
    optionList,
    foodGroupLoading,
    setModal,
    openModal,
    setOptions,
    setPayload,
    processGroup,
    onGetInputValue,
    setCreatedOption,
    handleChangeOptions,
    createEditOptionCallback,
  };
};
