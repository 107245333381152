import numeral from 'numeral';
import { Cell } from 'react-table';
import { DateRanges } from '../types/dashboard.types';

export const RangeStatus = [
  { value: 'today', option: 'Today' },
  { value: 'yesterday', option: 'Yesterday' },
  { value: 'this_week', option: 'This Week' },
  { value: 'last_week', option: 'Last Week' },
  { value: 'this_month', option: 'This Month' },
  { value: 'last_month', option: 'Last Month' },
  { value: 'last_six_months', option: 'Last Six Months' },
  // { value: 'CUSTOM', option: 'Custom Date' },
] as {
  value: DateRanges;
  option: string;
}[];

export const KitchenOrdersColumns = [
  {
    Header: 'S/N',
    Cell: ({ row }: Cell) => row.index + 1,
  },
  {
    Header: 'Kitchen Name',
    accessor: 'name',
  },
  {
    Header: 'Orders',
    accessor: '_count',
    Cell: ({ row }: Cell) => row.values._count.orders,
  },
];

export const popularMealsColumn = [
  {
    Header: 'S/N',
    Cell: ({ row }: Cell) => row.index + 1,
  },
  {
    Header: 'Meal',
    accessor: 'name',
  },
  {
    Header: 'Price',
    accessor: 'basePrice',
  },
  {
    Header: 'Orders',
    accessor: '_count',
    Cell: ({ row }: Cell) => row.values._count.orders,
  },
];

export const popularBrandColumn = [
  {
    Header: 'S/N',
    Cell: ({ row }: Cell) => row.index + 1,
  },
  {
    Header: 'Brand Name',
    accessor: 'brandName',
  },
  {
    Header: 'Orders',
    accessor: 'orders',
    Cell: ({ row }: Cell) => row.values.orders,
  },
];

export const popularCustomersColumn = [
  {
    Header: 'S/N',
    Cell: ({ row }: Cell) => row.index + 1,
  },
  {
    Header: 'Name',
    accessor: 'customer',
    Cell: ({ row }: Cell) => row.values.customer.fullName,
  },

  {
    Header: 'Amount Spent',
    accessor: '_sum',
    Cell: ({ row }: Cell) =>
      `₦${numeral(row.values._sum.totalPrice).format('0,0')}`,
  },
];

export const popularUsersColumn = [
  {
    Header: 'S/N',
    Cell: ({ row }: Cell) => row.index + 1,
  },
  {
    Header: 'Name',
    accessor: 'user',
    Cell: ({ row }: Cell) =>
      `${row.values.user.firstName}  ${row.values.user.lastName}`,
  },
  {
    Header: 'Phone Number',
    Cell: ({ row }: Cell) => row.values.user.phoneNumber,
  },
  {
    Header: 'Amount Spent',
    accessor: '_sum',
    Cell: ({ row }: Cell) =>
      `₦${numeral(row.values._sum.totalPrice).format('0,0')}`,
  },
];

export const popularLocationsColumn = [
  {
    Header: 'S/N',
    Cell: ({ row }: Cell) => row.index + 1,
  },
  {
    Header: 'Location',
    accessor: 'address',
    Cell: ({ row }: Cell) => row.values.address.name,
  },
  {
    Header: 'Area',
    Cell: ({ row }: Cell) => row.values.address.area,
  },
  {
    Header: 'Orders',
    accessor: '_count',
    Cell: ({ row }: Cell) => row.values._count,
  },
];
