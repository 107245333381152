import {
  ColorPicker,
  Loading,
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SecondaryButton,
  SelectInput,
} from '../../../../components';
import { useFoodGroups, useOptions } from '../../../../../hooks';
import { FoodGroup, Option } from '../../../../../types';
import { naira, selectionCounts } from '../../../../../constants';
import numeral from 'numeral';
import Modal from 'react-modal';

export const AddFoodGroup = ({
  group,
  duplicate,
  callback,
  closeModal,
}: {
  group?: string;
  duplicate?: boolean;
  callback?: (x: FoodGroup) => void;
  closeModal: () => void;
}) => {
  const {
    modal,
    payload,
    options,
    loading,
    editOption,
    optionList,
    foodGroupLoading,
    setModal,
    openModal,
    setPayload,
    processGroup,
    onGetInputValue,
    setCreatedOption,
    handleChangeOptions,
    createEditOptionCallback,
  } = useFoodGroups(closeModal, group, duplicate, callback);

  return (
    <div className="p-6 bg-white h-full">
      {(group ? foodGroupLoading : false) && <Loading />}

      <p>{duplicate ? 'Create' : group ? 'Edit' : 'Add'} food group</p>

      <div className="flex space-x-2 items-end">
        <PrimaryInput
          onChange={onGetInputValue}
          value={payload?.name}
          placeholder="Enter group name"
          required
          name="name"
          label="Food group name"
        />

        <PrimaryInput
          onChange={onGetInputValue}
          value={payload?.identifier ?? ''}
          placeholder="Identifier"
          name="identifier"
          label="Assign identifier"
        />

        <ColorPicker
          activeOption={payload.colorIdentifier}
          setActiveOption={(colorIdentifier) =>
            setPayload({ ...payload, colorIdentifier })
          }
        />
      </div>

      <p className="mt-7">Options</p>

      <SelectInput
        name="options"
        label="Add already existing options to this food group"
        placeholder="Select options"
        options={optionList?.data ?? []}
        handleChange={handleChangeOptions}
        getOptionValue={(item) => item?.id ?? ''}
        getOptionLabel={(item) =>
          `${item.name} - ${naira}${numeral(item.price).format('0,0')}`
        }
        value={options}
      />

      <div className="flex items-center space-x-3 mt-2">
        <div className="h-px flex-grow bg-neutral-300" />
        <p className="text-sm">or</p>
        <div className="h-px flex-grow bg-neutral-300" />
      </div>

      <button
        type="button"
        onClick={() => openModal()}
        className="w-full border border-neutral-400 rounded text-center py-2 px-4 mt-2"
      >
        Create a new option
      </button>

      <div className="mt-6">
        {options.map((o) => (
          <div
            key={o.id}
            className="bg-[#EBE8E6] rounded py-1.5 px-2 inline-flex space-x-2 mr-2"
          >
            <p className="text-sm">{o.name}</p>
            <button
              type="button"
              onClick={() => {
                openModal(o);
              }}
              className="px-1 py-0.5 bg-primary-brown text-white text-xs rounded"
            >
              edit
            </button>
          </div>
        ))}
      </div>

      <div className="flex space-x-2 items-end">
        <PrimarySelect
          onChange={onGetInputValue}
          options={selectionCounts}
          optionLabel="label"
          value={`${payload.minimumSelection}`}
          optionValue="value"
          label="Minimum number of options to be selected"
          name="minimumSelection"
          placeholder="Minimum"
          className="w-full"
        />
        <PrimarySelect
          onChange={onGetInputValue}
          options={selectionCounts}
          optionLabel="label"
          value={`${payload.maximumSelection}`}
          optionValue="value"
          label="Maximum number of options to be selected"
          name="maximumSelection"
          placeholder="Maximum"
          className="w-full"
        />
        <PrimaryInput
          onChange={onGetInputValue}
          value={payload?.position ?? 0}
          placeholder="Enter group position"
          required
          name="position"
          label="What is the position of the food group?"
          className="w-full"
        />
      </div>

      <div className="flex justify-between mt-5">
        <SecondaryButton onClick={closeModal} text="Cancel" />
        <PrimaryButton
          loading={loading}
          onClick={processGroup}
          text={`${duplicate ? 'Create' : group ? 'Edit' : 'Add'} Food Group`}
        />
      </div>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[430px] default-modal`}
        overlayClassName="modal-overlay"
      >
        <AddOption
          callback={setCreatedOption}
          editOption={editOption}
          toggle={createEditOptionCallback}
        />
      </Modal>
    </div>
  );
};

export const AddOption = ({
  duplicate,
  editOption,
  toggle,
  callback,
}: {
  duplicate?: boolean;
  editOption?: Option;
  toggle: () => void;
  callback?: (o: Option) => void;
}) => {
  const {
    option,
    foodItems,
    createOption,
    handleMultiSelect,
    onGetInputValue,
  } = useOptions(toggle, editOption, callback);

  return (
    <div className="p-8 bg-white rounded-lg">
      <p className="bold text-xl">
        {duplicate ? 'Create' : editOption ? 'Edit' : 'Add'} an option
      </p>

      <div className="grid grid-cols-1 gap-4 mt-2.5">
        <PrimaryInput
          onChange={onGetInputValue}
          value={option?.name ?? ''}
          placeholder="Enter option name"
          required
          name="name"
          label="What is the name of the option?"
          className="w-full"
        />

        <SelectInput
          singleSelect
          name="foodItem"
          options={foodItems?.data ?? []}
          placeholder="Select food item"
          handleChange={handleMultiSelect}
          label="Link the option to its corresponding food item"
          getOptionValue={(item) => item.id ?? ''}
          getOptionLabel={(item) => `${item.name} - ${item.brand?.name}`}
          value={foodItems?.data?.find((g) => option.foodItem === g.id)}
        />

        <PrimaryInput
          onChange={onGetInputValue}
          value={`${option?.price}`}
          placeholder="Enter option price"
          required
          name="price"
          label="What is the price of the option?"
          className="w-full"
          type="number"
        />
      </div>
      <div className="flex items-center space-x-4 mt-12">
        <PrimaryButton
          disabled={!!!option.name}
          text={`${duplicate ? 'Create' : editOption ? 'Edit' : 'Add'} Option`}
          type="button"
          onClick={createOption}
        />
        <SecondaryButton text="Cancel" type="button" onClick={toggle} />
      </div>
    </div>
  );
};
